import { onInsert, looseH as h } from './snabbdom';
import { isTouchDevice } from './device';
import { escapeHtml, frag, $as } from './common';
import { eventJanitor } from './event';
import * as xhr from './xhr';
import * as licon from './licon';
let dialogPolyfill;
// Safari versions before 15.4 need a polyfill for dialog. this "ready" promise resolves when that's loaded
site.load.then(async () => {
    window.addEventListener('resize', onResize);
    if (!window.HTMLDialogElement)
        dialogPolyfill = (await import(site.asset.url('npm/dialog-polyfill.esm.js')).catch(() => undefined))
            ?.default;
    site.pubsub.complete('dialog.polyfill');
});
// non-blocking window.alert-alike
export async function alert(msg) {
    await domDialog({
        htmlText: escapeHtml(msg),
        class: 'alert',
        show: 'modal',
    });
}
// non-blocking window.confirm-alike
export async function confirm(msg) {
    return ((await domDialog({
        htmlText: `<div>${escapeHtml(msg)}</div>
          <span><button class="button no">no</button><button class="button yes">yes</button></span>`,
        class: 'alert',
        noCloseButton: true,
        noClickAway: true,
        show: 'modal',
        actions: [
            { selector: '.yes', result: 'yes' },
            { selector: '.no', result: 'no' },
        ],
    })).returnValue === 'yes');
}
// when opts contains 'show', this promise resolves as show/showModal (on dialog close) so check returnValue
// otherwise, this promise resolves once assets are loaded and things are fully constructed but not shown
export async function domDialog(o) {
    const [html] = await loadAssets(o);
    const dialog = document.createElement('dialog');
    for (const [k, v] of Object.entries(o.attrs?.dialog ?? {}))
        dialog.setAttribute(k, String(v));
    if (isTouchDevice())
        dialog.classList.add('touch-scroll');
    if (o.parent)
        dialog.style.position = 'absolute';
    if (!o.noCloseButton) {
        const anchor = frag('<div class="close-button-anchor">');
        anchor.innerHTML = `<button class="close-button" aria-label="Close" data-icon="${licon.X}">`;
        dialog.appendChild(anchor);
    }
    const view = !html && o.append?.length === 1 ? o.append[0].node : document.createElement('div');
    view.classList.add('dialog-content');
    if (o.class)
        view.classList.add(...o.class.split(/[. ]/).filter(x => x));
    for (const [k, v] of Object.entries(o.attrs?.view ?? {}))
        view.setAttribute(k, String(v));
    if (html)
        view.innerHTML = html;
    const scrollable = frag(`<div class="${o.noScrollable ? 'not-' : ''}scrollable">`);
    scrollable.appendChild(view);
    dialog.appendChild(scrollable);
    (o.parent ?? document.body).appendChild(dialog);
    const wrapper = new DialogWrapper(dialog, view, o);
    if (o.show && o.show === 'modal')
        return wrapper.showModal();
    else if (o.show)
        return wrapper.show();
    return wrapper;
}
// snab dialogs without an onInsert callback are shown as modal by default. use onInsert callback to handle
// this yourself
export function snabDialog(o) {
    const ass = loadAssets(o);
    let dialog;
    return h(`dialog${isTouchDevice() ? '.touch-scroll' : ''}`, {
        key: o.class ?? 'dialog',
        attrs: o.attrs?.dialog,
        hook: onInsert(el => (dialog = el)),
    }, [
        o.noCloseButton ||
            h('div.close-button-anchor', h('button.close-button', { attrs: { 'data-icon': licon.X, 'aria-label': 'Close' } })),
        h(`div.${o.noScrollable ? 'not-' : ''}scrollable`, h('div.dialog-content' +
            (o.class
                ? '.' +
                    o.class
                        .split(/[. ]/)
                        .filter(x => x)
                        .join('.')
                : ''), {
            attrs: o.attrs?.view,
            hook: onInsert(async (view) => {
                const [html] = await ass;
                if (!o.vnodes && html)
                    view.innerHTML = html;
                const wrapper = new DialogWrapper(dialog, view, o);
                if (o.onInsert)
                    o.onInsert(wrapper);
                else
                    wrapper.showModal();
            }),
        }, o.vnodes)),
    ]);
}
class DialogWrapper {
    constructor(dialog, view, o) {
        this.dialog = dialog;
        this.view = view;
        this.o = o;
        this.actionEvents = eventJanitor();
        this.dialogEvents = eventJanitor();
        this.observer = new MutationObserver(list => {
            for (const m of list)
                if (m.type === 'childList')
                    for (const n of m.removedNodes) {
                        if (n === this.dialog) {
                            this.onRemove();
                            return;
                        }
                    }
        });
        this.show = () => {
            this.dialog.show();
            this.autoFocus();
            return new Promise(resolve => (this.resolve = resolve));
        };
        this.showModal = () => {
            this.view.scrollTop = 0;
            this.dialog.showModal();
            this.autoFocus();
            return new Promise(resolve => (this.resolve = resolve));
        };
        this.close = (v) => {
            this.dialog.close(v || this.returnValue || 'ok');
        };
        // attach/reattach existing listeners or provide a set of new ones
        this.updateActions = (actions = this.o.actions) => {
            this.actionEvents.removeAll();
            if (!actions)
                return;
            for (const a of Array.isArray(actions) ? actions : [actions]) {
                for (const event of Array.isArray(a.event) ? a.event : a.event ? [a.event] : ['click']) {
                    for (const el of a.selector ? this.view.querySelectorAll(a.selector) : [this.view]) {
                        const listener = 'listener' in a ? (e) => a.listener(e, this, a) : () => this.close(a.result);
                        this.actionEvents.addListener(el, event, listener);
                    }
                }
            }
        };
        this.onRemove = () => {
            this.observer.disconnect();
            if (!this.dialog.returnValue)
                this.dialog.returnValue = 'cancel';
            this.restore?.focus?.focus(); // one modal at a time please
            if (this.restore?.overflow !== undefined)
                document.body.style.overflow = this.restore.overflow;
            this.restore = undefined;
            this.resolve?.(this);
            this.o.onClose?.(this);
            this.dialog.remove();
            for (const css of this.o.css ?? []) {
                if ('hashed' in css)
                    site.asset.removeCssPath(css.hashed);
                else if ('url' in css)
                    site.asset.removeCss(css.url);
            }
            this.actionEvents.removeAll();
            this.dialogEvents.removeAll();
        };
        if (dialogPolyfill)
            dialogPolyfill.registerDialog(dialog); // ios < 15.4
        const justThen = Date.now();
        const cancelOnInterval = (e) => {
            if (Date.now() - justThen < 200)
                return;
            const r = dialog.getBoundingClientRect();
            if (e.clientX < r.left || e.clientX > r.right || e.clientY < r.top || e.clientY > r.bottom)
                this.close('cancel');
        };
        this.observer.observe(document.body, { childList: true, subtree: true });
        view.parentElement?.style.setProperty('---viewport-height', `${window.innerHeight}px`);
        this.dialogEvents.addListener(view, 'click', e => e.stopPropagation());
        this.dialogEvents.addListener(dialog, 'cancel', () => !this.returnValue && (this.returnValue = 'cancel'));
        this.dialogEvents.addListener(dialog, 'close', this.onRemove);
        if (!o.noCloseButton)
            this.dialogEvents.addListener(dialog.querySelector('.close-button-anchor > .close-button'), 'click', () => this.close('cancel'));
        if (!o.noClickAway)
            setTimeout(() => {
                this.dialogEvents.addListener(document.body, 'click', cancelOnInterval);
                this.dialogEvents.addListener(dialog, 'click', cancelOnInterval);
            });
        for (const app of o.append ?? []) {
            if (app.node === view)
                break;
            const where = (app.where ? view.querySelector(app.where) : view);
            if (app.how === 'before')
                where.before(app.node);
            else if (app.how === 'after')
                where.after(app.node);
            else
                where.appendChild(app.node);
        }
        this.updateActions();
        this.dialogEvents.addListener(this.dialog, 'keydown', onKeydown);
    }
    get open() {
        return this.dialog.open;
    }
    get returnValue() {
        return this.dialog.returnValue;
    }
    set returnValue(v) {
        this.dialog.returnValue = v;
    }
    autoFocus() {
        const focus = (this.o.focus ? this.view.querySelector(this.o.focus) : this.view.querySelectorAll(focusQuery)[1]);
        if (!focus)
            return;
        focus.focus();
        if (focus instanceof HTMLInputElement)
            focus.select();
    }
}
function loadAssets(o) {
    return Promise.all([
        o.htmlUrl
            ? xhr.text(o.htmlUrl)
            : Promise.resolve(o.cash ? $as($(o.cash).clone().removeClass('none')).outerHTML : o.htmlText),
        ...(o.css ?? []).map(css => 'hashed' in css ? site.asset.loadCssPath(css.hashed) : site.asset.loadCss(css.url)),
    ]);
}
function onKeydown(e) {
    if (e.key === 'Tab') {
        const $focii = $(focusQuery, e.currentTarget), first = $as($focii.first()), last = $as($focii.last()), focus = document.activeElement;
        if (focus === last && !e.shiftKey)
            first.focus();
        else if (focus === first && e.shiftKey)
            last.focus();
        else
            return;
        e.preventDefault();
    }
    e.stopPropagation();
}
function onResize() {
    // ios safari vh behavior workaround
    $('dialog > div.scrollable').css('---viewport-height', `${window.innerHeight}px`);
}
const focusQuery = ['button', 'input', 'select', 'textarea']
    .map(sel => `${sel}:not(:disabled)`)
    .concat(['[href]', '[tabindex="0"]', '[role="tab"]'])
    .join(',');
